// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WqcWokiMCkCwd6dLNdPH{width:100%;height:100%;display:flex;justify-content:space-between;align-items:center}.xI2ID_XpGMOiv1h4fz4A{border:unset;background-color:inherit;flex-grow:1;width:100px}.qJ0VpCuKWNqZMHlxTqLI{background-color:#fff}.DzJ_N850wy0xnmnpZvRJ{background-color:hsla(0,100%,90%,.8)}`, "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/components/TextInputWithSubmit.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,wBAAA,CACA,WAAA,CACA,WAAA,CAGJ,sBACI,qBAAA,CAGJ,sBACI,oCAAA","sourcesContent":[".line {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.input {\r\n    border: unset;\r\n    background-color: inherit;\r\n    flex-grow: 1;\r\n    width: 100px;\r\n}\r\n\r\n.valid {\r\n    background-color: white;\r\n}\r\n\r\n.invalid {\r\n    background-color: rgba(255, 204, 204, 0.8);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var line = `WqcWokiMCkCwd6dLNdPH`;
export var input = `xI2ID_XpGMOiv1h4fz4A`;
export var valid = `qJ0VpCuKWNqZMHlxTqLI`;
export var invalid = `DzJ_N850wy0xnmnpZvRJ`;
export default ___CSS_LOADER_EXPORT___;
